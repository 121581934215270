<template>
  <vx-card v-show="!loading">
    <vs-row>
      <h4 class="mb-5">Add Social Media</h4>
    </vs-row>

    <vs-row vs-w="12">
      <vs-col vs-w="6">
        <vs-row vs-w="12">
          <vs-col vs-w="12" class="mb-3">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-user"
              icon-no-border
              label="Name (*)"
              v-model="payload.name"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("name")
            }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mb-3">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-link"
              icon-no-border
              label="Social Media URL (*)"
              placeholder="https://reservancy.com/blabla/blabla"
              v-model="payload.link"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("link")
            }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mt-3">
            <div class="vx-col w-full" @click.prevent="storeData">
              <vs-button class="mr-3 mb-2">Submit</vs-button>
            </div>
          </vs-col>
        </vs-row>
      </vs-col>

      <vs-col vs-w="6" class="pl-8">
        <div class="flex items-start flex-col sm:flex-row">
          <img :src="url" class="rounded mr-4" width="150" height="150" />
          <div>
            <input
              type="file"
              @change="addImage"
              class="hidden"
              ref="update_logo_input"
              accept="image/*"
            />
            <vs-button
              @click="$refs.update_logo_input.click()"
              class="mr-4 mb-2"
              type="filled"
              icon-pack="feather"
              icon="icon-edit"
            ></vs-button>
            <vs-button
              @click="removeImage"
              type="border"
              color="danger"
              icon-pack="feather"
              icon="icon-trash"
            ></vs-button>
          </div>
        </div>
        <span class="block text-sm mt-2 text-danger">{{
          errors.first("logo")
        }}</span>
      </vs-col>
    </vs-row>
  </vx-card>
</template>

<script>
import vSelect from "vue-select";

// Store Module
import moduleSocialMediaManagement from "@/store/social-media-management/moduleSocialMediaManagement.js";

export default {
  name: "SocialMediaAdd",

  metaInfo: {
    title: "Edit Social Media",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },

  components: {
    "v-select": vSelect
  },

  data: () => ({
    payload: {
      name: "",
      link: "",
      logo: ""
    },

    url: "https://via.placeholder.com/150",
    loading: true
  }),

  computed: {
    decryptedID() {
      return this.$secure.decrypt(this.$route.params.id);
    }
  },

  methods: {
    addImage(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
      this.payload.logo = file;
    },

    removeImage() {
      this.url = "https://via.placeholder.com/150";
      this.payload.logo = "";
    },

    storeData() {
      const formData = new FormData();
      // set formData
      formData.set("name", this.payload.name);
      formData.set("link", this.payload.link);
      formData.set('_method', 'PUT');
      if (this.payload.logo !== "") {
        formData.set("logo", this.payload.logo);
      }

      this.$store
        .dispatch("socialMediaManagement/updateSocialMedia", {
            id: this.decryptedID,
            body: formData
        })
        .then(({ data: res }) => {
          this.$vs.notify({
            title: "Successful",
            text: res.msg_status,
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "success"
          });

          this.$router.go(-1);
        })
        .catch(err => {
          this.$catchErrorResponse(err)
        });
    },

    getData () {
      this.$vs.loading({
        type: 'sound'
      })

        this.$store.dispatch('socialMediaManagement/fetchSocialMedia', this.decryptedID)
                    .then(({data: res}) => {
                        this.payload.name = res.data.name
                        this.payload.link = res.data.link
                        this.url = res.data.logo
                    })
                    .catch(err => this.$catchErrorResponse(err))
                    .finally(() => {
                      this.loading = false
                      this.$vs.loading.close()
                    })
    }
  },

  created() {
    if (!moduleSocialMediaManagement.isRegistered) {
      this.$store.registerModule(
        "socialMediaManagement",
        moduleSocialMediaManagement
      );
      moduleSocialMediaManagement.isRegistered = true;
    }

    this.getData()
  }
};
</script>

<style></style>
